<template>
  <div>
    <b-card>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="mr-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input type="text" placeholder="검색어를 입력해주세요" v-model="form.list.search" @keypress.enter.prevent.stop="list" v-focus></b-form-input>
      </b-input-group>
      <b-button class="mr-1" variant="primary" @click="list" :disabled="busy.list">
        검색
        <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
      </b-button>
      <b-button class="mr-1" variant="success" @click="showModal" :disabled="busy.add">
        추가
        <b-spinner class="ml-1" small v-if="busy.add"></b-spinner>
      </b-button>
      <b-button class="mr-1" variant="danger" @click="removeSynonym" :disabled="busy.remove">
        삭제
        <b-spinner class="ml-1" small v-if="busy.remove"></b-spinner>
      </b-button>
      <b-button variant="outline-info" class="pull-right" @click="modal.help = true"><i class="fa fa-question"></i></b-button>

      <hr/>

      <div class="clearfix mb-1">
        <div class="pull-right">
          <xlsx :types="['xlsx']" :preFunc="preDown" :data="xlsx.selected" :labels="xlsx.labels" :keys="xlsx.keys" name="Brands"></xlsx>
        </div>
      </div>

      <c-table :table-data="items.list" :fields="fields.list" :perPage.sync="perPage"
               :isBusy="busy.list" :getMoreBusy="busy.listmore" :hasMore="hasMore.list"
               :caption="items.list.length + ' 개'" @get-more="list(true)">
      </c-table>

      <b-modal title="동의어 추가" size="lg" v-model="modal.add">
        <b-alert variant="warning" show>추가된 동의어는 최대 24시간 내 반영됩니다</b-alert>
        <b-form-radio-group class="col-form-label" v-model="form.add.type" :options="[
          {text: '브랜드', value: 'designer'},
          {text: '카테고리', value: 'category'},
          {text: '키워드', value: 'keyword'},
        ]"></b-form-radio-group>
        <div class="label-sm">검색어</div>
        <b-input v-model="form.add.word" placeholder="고객이 검색할 검색어를 입력해주세요"></b-input>
        <div class="label-sm">치환 후 단어</div>
        <b-input v-model="form.add.synonym" placeholder="브랜드, 카테고리 등 대응되는 단어를 정확히 입력해주세요"></b-input>

        <template v-slot:modal-footer="{ ok, cancel }">
          <b-button variant="success" @click="addSynonym()">
            추가
          </b-button>
          <b-button variant="primary" @click="cancel()">
            닫기
          </b-button>
        </template>
      </b-modal>

      <synonym-help ref="help" :value.sync="modal.help"></synonym-help>
    </b-card>
  </div>
</template>

<script>
import xlsx from '@/views/modules/Xlsx.vue'
import synonymHelp from '@/views/meta/SynonymHelp.vue'

export default {
  name: "Synonym",
  title: '동의어 관리',
  components: {xlsx, synonymHelp},
  data() {
    return {
      fields: {
        list: [
          {key: 'selected', class: 'w-65px'},
          {key: 'typeName', label: '유형', class: 'text-center w-100px'},
          {key: 'word', label: '검색어', class: 'text-center'},
          {key: 'synonym', label: '치환 후 단어', class: 'text-center'},
        ]
      },
      form: {
        list: {
          search: ''
        },
        add: {
          type: 'designer',
          word: '',
          synonym: '',
        }
      },
      lastBody: {list: {}},
      items: {list: []},
      busy: {list: false, add: false, remove: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      modal: {add: false, help: false},
      perPage: 20,

      xlsx: {
        selected: [],
        keys: ['typeName', 'word', 'synonym'],
        labels: ['유형', '검색어', '치환 후 단어'],
      }
    };
  },
  async created() {
    this.$utils.getStatus(this.$options.name, this, 'perPage');
    this.list();
  },
  methods: {
    async list(more) {
      let j = await this.$api.postTable(this, '/meta/synonym', {search: this.form.list.search}, {more, fnAssign: this.assignTableData});
    },
    assignTableData(e) {
      e.typeName = {designer: '브랜드', category: '카테고리', keyword: '키워드'}[e.word_type];
      return e;
    },
    showModal() {
      this.modal.add = true;
      this.form.add.word = '';
      this.form.add.synonym = '';
    },
    async addSynonym() {
      this.modal.add = false;
      this.busy.add = true;
      let j = await this.$api.postJson('/meta/synonym/add', {...this.form.add});
      this.busy.add = false;
      if (j) {
        this.list();
      }
    },
    async removeSynonym() {
      const selected = this.items.list.filter(e => e.selected);
      if (selected.length === 0) return alert('삭제할 동의어를 선택해 주시기 바랍니다.');
      if (!confirm(`${selected.length} 개의 동의어를 정말로 삭제하시겠습니까?`)) return;
      this.busy.remove = true;
      let j = await this.$api.postJson('/meta/synonym/remove', {ids: selected.map(e => e.id)});
      this.busy.remove = false;
      if (j) {
        this.list();
      }
    },
    preDown() {
      this.xlsx.selected = this.items.list.filter(e => e.selected);
      if (!this.xlsx.selected.length) return alert('다운받을 동의어를 선택해주세요');
      return true;
    },
  }
}
</script>
