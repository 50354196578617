<template>
  <div>
    <b-tabs v-model="tabIndex">
      <b-tab title="동의어 관리">
        <Synonym></Synonym>
      </b-tab>
      <b-tab title="키워드 관리">
        <Keyword v-if="loaded[1] || tabIndex === 1"></Keyword>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>

import Synonym from '@/views/meta/Synonym.vue'
import Keyword from '@/views/meta/Keyword.vue'

export default {
  name: 'SearchKeyword',
  title: '검색 키워드 관리',
  components: {Synonym, Keyword},
  data() {
    return {
      tabIndex: 0,
      loaded: {}, // {[tabIndex]: true} - lazy loading
    }
  },
  watch: {
    tabIndex(v) {
      this.loaded[v] = true;
    },
  },
}
</script>
