<template>
  <div>
    <b-modal title="동의어 설정 안내" size="xl" v-model="v" ok-only>
      <h6>* 동의어는 모호하지 않고 정확히 매핑되는 단어가 입력되어야 합니다(ex: 티 - x, 롱슬리브 - o).</h6>
      <h6>* 동의어는 기본적으로 브랜드, 카테고리의 전체 단어, 브랜드, 상품명을 띄어쓰기로 분리한 단어, 카테고리를 / 로 분리한 단어에 대해 동작합니다.</h6>
      <h6>* 동의어는 검색어의 단어 교체의 기준이어서 전체에 대해서 unique 해야 합니다.</h6>
      <br/>
      <h6>* 동의어에 매핑된 단어를 추가로 검색엔진에 탑재합니다.</h6>
      <h6>* 검색엔진은 상품정보 변경시 갱신되거나 매일 새벽 1시 30분에 전체 갱신됩니다.</h6>
      <h6>* 유형은 브랜드, 카테고리로 나뉘며 구분 용도로만 쓰입니다.</h6>
      <h6>* 아래의 예시를 참고해주세요.</h6>
      <hr/>

      <h5>동의어: 마몽뜨, 치환 후 단어: 마몬트</h5>
      <div>
        상품명: GUCCI 구찌 GG 마몬트 퀼트 클러치
      </div>
      <div>
        검색엔진 탑재: GUCCI,구찌,GG,마몬트,퀼트,클러치,마몽뜨
      </div>
      <hr/>

      <h5>동의어: 골프채, 치환 후 단어: 골프 클럽</h5>
      <div>
        카테고리: 골프 > 골프 클럽
      </div>
      <div>
        검색엔진 탑재: 골프,골프 클럽,골프채
      </div>
    </b-modal>
  </div>
</template>
<style scoped>
</style>
<script>

export default {
  name: 'SynonymHelp',
  model: {prop: 'value', event: 'change'},
  props: {
    value: Boolean,
  },
  data() {
    return {

    }
  },
  computed: {
    v: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('update:value', v);
      }
    }
  },
  async created() {
  },
  methods: {
  }
}
</script>
